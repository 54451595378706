<template>
  <div id="page-user-view">
    <div id="user-data">
      <vx-card class="mx-0 rounded-none shadow-none pb-10 negative-tabs" v-if="child">
        <!-- Avatar -->
        <ul class="ph-teacher-profile">
          <li class="flex items-center">
            <vs-avatar size="130px" :src="child.photo"/>
            <div class="ph-profile-name ml-3">
              <h1 class="mb-1">{{ child.fullName}} </h1>
              <h4 class="mb-1"> <strong v-if="child.ageDetails.length > 0"> Age Group: </strong> {{ child.ageDetails.length > 0 ? child.ageDetails[0].ageGroup : ''}} </h4>
              <h4><strong> Class: </strong>{{ child.roomDetails ? child.roomDetails[0].name : ''}} </h4>
            </div>
          </li>
        </ul>
      </vx-card>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
export default {

  data() {
    return {
      childId:  this.$route.params.childId,
      child: '',
    }
  },
  methods: {
    ...mapActions("child", [
      "getChildInfoById"
    ]),

    async getChild(childId) {
      await this.getChildInfoById(childId)
        .then((res) => {
          this.child = res.data.data[0];
        })
        .catch(err => {
          //this.$vs.loading.close()
        })

    },
  },
  mounted() {
    this.getChild(this.childId);
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

.con-vs-avatar.large {
  width: 71px;
  height: 71px;
  @media (max-width: 576px){
    width: 50px;
    height: 50px;
  }
}

.teacher-list {
  @media (max-width: 576px){
    margin-bottom: 30px;
  }
  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px){
      max-width: 82px;
      margin-right: 10px;
    }
    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px){
          border: 2px solid #289ad5;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
