<template>
  <div>
    <div v-if="showProfile">
      <vx-card>
        <gallery-slider @activitySelected="activitySelected"
        ></gallery-slider>
      </vx-card>
      <vx-card class="add-activity-card" style="margin-top: 20px; border-radius: 8px;">
        <h2>{{ selectedChild.fullName }} has {{ totalActivityCount }} documented play experiences</h2>
        <h3 class="h4"> Core Skills</h3>
        <ul class="centerx flex-wrap">
          <li v-for="(index, key) in coreSkills" style="display: inline-flex; flex-wrap: wrap;" :key="key">
            <div class="radio-wrap">
              <div>
                <img :src="index.icon ? index.icon : $defaultImage" :alt="index.name" height="50px" width="50px">
                <span>{{ index.name }}</span>
                <span>{{ index.activities ? index.activities.numberOfActivities : 0 }}</span>
              </div>
            </div>
          </li>
        </ul>

        <br/>
        <h3 class="h4">Ways Of Learning</h3>

        <ul class="centerx flex-wrap">
          <li v-for="(index, key) in waysOfLearnings" style="display: inline-flex; flex-wrap: wrap;" :key="key">

            <div class="radio-wrap">
              <div>
                <img :src="index.icon ? index.icon  : $defaultImage" :alt="index.name" height="50px" width="50px">
                <span>{{ index.name }}</span>
                <span>{{ index.activities ? index.activities.numberOfActivities : 0 }}</span>

              </div>
            </div>
          </li>
        </ul>

        <br/>
        <h3 class="h4">STREAM</h3>

        <ul class="centerx flex-wrap">
          <li style="display: inline-flex; flex-wrap: wrap;" v-for="(index, key) in streams" :key="key">
            <div class="radio-wrap">
              <div>
                <img :src="index.icon ? index.icon  : $defaultImage" :alt="index.name" height="50px" width="50px">
                <span>{{ index.name }}</span>
                <span>{{ index.activities ? index.activities.numberOfActivities : 0 }}</span>
              </div>
            </div>
          </li>
        </ul>
      </vx-card>
    </div>
    <div v-if="showGalleryDetail" id="page-user-view">
      <vx-card class="mb-base">
        <div class="vx-breadcrumb mb-5 md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <a href="javascript:void(0)" class="" @click="goBack"> Learning stories</a>
              <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                          points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
            </li>
            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class="">{{ moment(activity.updatedAt) + ' - ' + activity.name }}</a>
            </li>
          </ul>
        </div>

        <h2>Gallery</h2>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div v-for="(photo, index) in media" :key="index" class="ph-img-list">
            <a>
              <img :src="photo.src || photo.thumb" class="responsive h-64 object-cover" @click="openGallery(index)">
            </a>

          </div>
        </div>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-light-box="false"
          :show-caption="false"
          :show-thumbs="false"
        />
        <div class="pagination-div flex justify-center float-none mt-5">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
          ></paginate>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import GallerySlider from './GallerySlider'
import LightBox from "vue-image-lightbox";
import Vue from 'vue'
import VueLodash from 'vue-lodash'
import _ from 'lodash'
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import moment from "moment";

Vue.use(VueLodash, {name: 'custom', lodash: _})

export default {
  components: {GallerySlider, LightBox},
  data() {
    return {
      showProfile: true,
      showGalleryDetail: false,
      activity: '',
      coreSkills: [],
      waysOfLearnings: [],
      streams: [],
      serverResponded: false,
      activityPhotos: [],
      selectedChild: '',
      totalActivityCount: 0,
      media: [],
      currentImageIndex: 0
    }
  },
  props: {
    child: '',
  },
  methods: {
    ...mapActions("coreSkill", [
      "fetchCoreSkillWithActivityCount"
    ]),
    ...mapActions("stream", [
      "fetchStreamWithActivity"
    ]),
    ...mapActions("waysOfLearning", [
      "fetchWaysOfLearningWithActivity"
    ]),
    goBack() {
      this.showGalleryDetail = false;
      this.showProfile = true;
    },
    moment(date) {
      return moment(date).format('DD/MM/YY')
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
      this.currentImageIndex = index;
    },
    handleChangePage(page) {
      let photos = this.activity.photos;
      for (let i = 1; i <= this.totalPage; i++) {
        if (i === page) {
          this.activityPhotos = photos.slice((i * 15) - 15, (i * 15))
          this.media = this.activityPhotos.map((item) => {
            return {
              id: item._id,
              thumb: item.photo,
              src: item.photo,
              srcset: item.photo,
            };
          });
        }
      }
    },
    activitySelected() {
      this.showProfile = false;
      this.showGalleryDetail = true;
      this.activity = this.$store.state.activity.activity;
      this.activityPhotos = this.activity.photos;
      this.activityPhotos = this.activityPhotos.slice(0, 15)
      this.media = this.activityPhotos.map((item) => {
        return {
          id: item._id,
          thumb: item.photo,
          src: item.photo,
          srcset: item.photo,
        };
      });
    },
    async getCoreSkills(roomId, childId) {
      let data = {
        roomId: roomId,
        childId: childId
      };
      this.$vs.loading();
      await this.fetchCoreSkillWithActivityCount(data).then((response) => {
        this.$vs.loading.close();

        this.coreSkills = response.data.data;
      }).catch(err => {
        this.$vs.loading.close();
        console.log(err);
      })

    },
    async getStream(roomId, childId) {
      let data = {
        roomId: roomId,
        childId: childId
      };
      await this.fetchStreamWithActivity(data).then((response) => {
        this.streams = response.data.data;
      }).catch(err => {
        console.log(err);
      });
    },
    async getWaysOfLearnings(roomId, childId) {
      let data = {
        roomId: roomId,
        childId: childId
      };
      await this.fetchWaysOfLearningWithActivity(data).then((response) => {
        this.waysOfLearnings = response.data.data;
      })
    },
    async getTotalCountActivity() {
      this.totalActivityCount = await _.sumBy(this.coreSkills, function (o) {
        return o.activities ? o.activities.numberOfActivities : 0;
      })
    }
  },
  async mounted() {
    this.selectedChild = this.child;
    await this.getCoreSkills(this.child.roomId, this.child._id);
    await this.getWaysOfLearnings(this.child.roomId, this.child._id);
    await this.getStream(this.child.roomId, this.child._id);
    await this.getTotalCountActivity();
  },
  computed: {
    childId() {
      return this.$route.params.childId;
    },
    totalPage() {
      if (this.activity) {
        return Math.ceil(this.activity.photos.length / 15);
      }
      return 0;
    },
  },
}

</script>
