<template>
  <div>
    <vx-card class="mb-base" v-if="!storyId">
      <h2>Child Stories</h2>
      <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
        <div v-if="stories.length > 0" v-for="story in stories" :key="story._id" class="ph-img-list">
          <a href="javascript::void(0)" @click="showStory(story._id)">
            <img :src="getDisplayImage(story) ? getDisplayImage(story) : $defaultImage "
                 class="responsive h-64 object-cover">
            <h4>{{ story.title }}</h4>
            <span class="mt-5 block"> By {{ story.filledBy.fullName }}</span>
          </a>
        </div>
        <div v-if="stories.length < 1" class="ph-img-list">
          <p>No child story found.</p>
        </div>
      </div>
    </vx-card>
    <div v-if="storyId">
      <vx-card class="mb-base">
        <vs-breadcrumb align="left" separator="chevron_right" class="mb-4">
          <li><a href="javascript:void(0)" @click="showStories">Stories</a></li>
        </vs-breadcrumb>

        <h2>Child Story</h2>
        <div class="mb-5">
          <h3>{{ childStory.title }}</h3>
          <img :src="childStory.displayImage" class="w-full">
        </div>
        <div class="">
          <div v-for="item in childStory.stories" :key="item._id" class="mb-5">
            <h3 class="mb-2 text-black">{{ item.instruction }}</h3>
            <p v-if="item.type == 'text'" class="mb-2">{{ item.story }}</p>
            <div class="detail-img-wrapper" v-if="item.type == 'image'"><img :src="item.story"></div>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-base px-16">
        <h2 class="text-black">Teacher's Responses</h2>
        <div class="">
          <div v-for="response in childStory.responses" :key="response._id" class="mb-10">
            <div class="flex items-center mb-2">
              <vs-avatar size="48px" :src="response.respondedBy.profileImage"/>
              <h5 class="ml-3 font-semibold">{{ response.respondedBy.fullName }}</h5>
            </div>
            <p>{{ response.response }}</p>
          </div>
        </div>
        <div>
          <vs-textarea :class="comment ?'hasValue':''" label="Today's comment" name="comment" v-model="comment"
                       v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('comment')">{{ errors.first('comment') }}</span>
          <div class="flex mb-4">
            <div class="w-full bg-grid-color h-12 text-right">
              <vs-button color="primary" type="filled" @click="submitComment">Save</vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
  import {mapActions} from "vuex";
  import _ from "lodash";

  export default {
    data() {
      return {
        storyId: null,
        childId: this.$route.params.childId,
        childStory: {},
        stories: [],
        comment: "",
      }
    },
    methods: {
      ...mapActions("childrenStories", [
        "fetchChildStories",
        "getChildStoryById",
        "addChildStoryComment",

      ]),
      getDisplayImage(story) {
        const imageExistStory = _.filter(story.stories, {'type': 'image'});
        if (imageExistStory.length) {
          return imageExistStory[0].story;
        }
        return false;
      },
      showStories() {
        this.storyId = "";
      },
      showStory(storyId) {
        this.getChildStoryByStoryId(storyId);
        this.storyId = storyId;
      },
      submitComment() {

        this.$validator.validateAll().then(result => {
          if (result) {

            let data = {
              storyId: this.storyId,
              childId: this.childId,
              teacherName: this.teacher.fullName,
              comment: this.comment,
              responseBy: this.teacher._id,
            };
            this.addChildStoryComment(data).then(res => {
              this.getChildStoryByStoryId(this.storyId);
              this.comment = "";
              this.errors.clear()
              this.$validator.reset();

              this.showMessage('Success', 'Data Saved', 'success', 'icon-check-circle')

            }).catch(err => {
              this.showMessage('Error', err.message, 'error', 'icon-times')
            })
          }
        })
      },
      showMessage(title, message, color, icon = 'icon-check-circle') {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: icon,
          color: color
        })
      },
      async getChildStoryByStoryId(storyId) {
        await this.getChildStoryById(storyId).then(res => {
          this.childStory = res.data.data;
        })
      },
      async getChildStories() {
        await this.fetchChildStories(this.childId).then(res => {
          this.stories = res.data.data;
        })
      },
    },
    mounted() {

      if (this.$route.query.storyId && this.$route.query.storyId != "") {
        this.storyId = this.$route.query.storyId;
        this.showStory(this.storyId);
      }
      this.teacher = this.$store.state.AppActiveUser;
      this.getChildStories();

    },
    computed: {},
  }

</script>
