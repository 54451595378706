<template>
  <div>
    <vx-card>
      <div class="datepicker-wrapper hasIcon" style="width: 15%;">
        <vs-icon icon="calendar_today" class="icon"></vs-icon>
        <datepicker
          icon="icon icon-user"
          icon-pack="feather"
          :format="dateFormat"
          v-model="selectedDate"
          class="w-full"
        ></datepicker>
      </div>
      <div
        id="data-list-thumb-view"
        class="data-list-container daily-diary-table"
      >
        <table class="table w-full">
          <thead>
            <tr>
              <th class="activity">Activity</th>
              <th class="description">Description</th>
              <th class="core-skill text-center">Core skill</th>
              <th class="ways-of-learning text-center">Ways of learning</th>
              <th class="stream text-center">STREAM</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="activities.length > 0"
              v-for="activity in activities"
              :key="activity._id"
            >
              <td valign="top" class="activity">{{ activity.name }}</td>
              <td colspan="4" class="px-0">
                <table class="inner-table">
                  <tbody>
                    <tr>
                      <td valign="top" class="description">
                        {{ activity.description }}
                      </td>

                      <td valign="top" class="core-skill text-center">
                        <img
                          v-if="activity.coreSkill.icon !== ''"
                          :src="activity.coreSkill.icon"
                          class="inline h-16 object-contain"
                        />
                        <br />
                        {{ activity.coreSkill ? activity.coreSkill.name : "" }}
                      </td>
                      <td valign="top" class="ways-of-learning text-center">
                        <img
                          v-if="activity.waysOfLearning.icon"
                          :src="activity.waysOfLearning.icon"
                          class="inline h-16 object-contain"
                        />
                        <br />
                        {{
                          activity.waysOfLearning
                            ? activity.waysOfLearning.name
                            : ""
                        }}
                      </td>
                      <td valign="top" class="stream text-center">
                        <img
                          v-if="activity.streams.icon"
                          :src="activity.streams.icon"
                          class="inline h-16 object-contain"
                        />

                        <br />
                        {{ activity.streams ? activity.streams.name : "" }}
                      </td>
                    </tr>
                    <tr v-if="activity.photos">
                      <td colspan="4">
                        <ul class="flex flex-wrap">
                          <li
                            v-for="photo in activity.photos"
                            :key="photo._id"
                            class="w-1/3 sm:w-1/4 xl:w-2/12 story-image-container photo-item"
                          >
                            <div class="thumbnail-wrap">
                              <img
                                v-if="hasAllowedVideoExtension(photo.photo)"
                                :src="convertVideoFileExtenion(photo.photo)"
                              />
                              <img
                                v-if="hasAllowedVideoExtension(photo.photo)"
                                class="playButton"
                                style="max-height: 70px"
                                src="https://personhood.s3.ap-southeast-2.amazonaws.com/undefined/play-button-svgrepo-com.svg"
                              />
                              <img v-else :src="photo.photo" />
                            </div>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr v-if="activities.length <= 0">
              <td colspan="5" class="text-center">
                There are no activities for this day.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <vx-card>
      <h2>Today's Comments</h2>
      <ul>
        <li
          v-if="comments.length > 0"
          v-for="(comment, key) in comments"
          :key="key"
        >
          - {{ comment.comment }}
        </li>
        <li v-if="comments.length < 1">no daily comment found.</li>
      </ul>
    </vx-card>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";
const ALLOWED_VIDEO_FILETYPES = ["mp4", "mov", "avi", "webm"];

export default {
  components: { Datepicker },
  data() {
    return {
      dateFormat: "dsu MMMM yyyy",
      selectedDate: "",
      activities: [],
      comments: []
    };
  },
  props: {
    child: ""
  },
  watch: {
    selectedDate(response) {
      let todayDate = new Date();
      let selectedDate = new Date(response);

      let selectedDateForHeading =
        selectedDate.getMonth() +
        1 +
        "/" +
        selectedDate.getDate() +
        "/" +
        selectedDate.getFullYear();

      if (todayDate.setHours(0, 0, 0, 0) == selectedDate.setHours(0, 0, 0, 0)) {
        this.dateFormat = "dsu MMMM yyyy (Today)";
        this.dateTitle = "Today " + selectedDateForHeading;
      } else {
        this.dateFormat = "dsu MMMM yyyy";
        this.dateTitle = selectedDateForHeading;
      }
      this.getActivities(selectedDate);
      this.fetchDailyComment(selectedDate);
    }
  },
  methods: {
    ...mapActions("activity", ["fetchDailyDiaryByChildId"]),
    ...mapActions("teacher", ["getDailyComment"]),
    /* *********** check video ext *************** */
    hasAllowedVideoExtension(url) {
      const extension = url.split(".").pop();
      return ALLOWED_VIDEO_FILETYPES.includes(extension);
    },

    convertVideoFileExtenion(url) {
      const thumbArray = url.split("/");
      const thumbUrl = thumbArray[4];
      let thumbIndex = null;
      if (thumbUrl.length) {
        const thumbIndexArray = thumbUrl.split(".");
        thumbIndex = thumbIndexArray[0];
      }
      return `${process.env.VUE_APP_IMAGE_URL}undefined/video-thumb/${thumbIndex}.png`;
    },
    async getTodayDate() {
      this.selectedDate = await new Date().toISOString().slice(0, 10);
    },
    async getActivities(date) {
      this.$vs.loading();
      let param = "?date=" + date;
      let data = { childId: this.childId, date: param };

      this.fetchDailyDiaryByChildId(data)
        .then(res => {
          this.$vs.loading.close();

          this.activities = res.data.data;
        })
        .catch(err => {
          this.$vs.loading.close();
          // console.log(err)
        });
    },
    async fetchDailyComment(date) {
      const data = {
        userId: this.teacher._id,
        date: date
      };
      await this.getDailyComment(data)
        .then(async res => {
          this.comments = await res.data.data;
        })
        .catch(err => {
          // console.log(err);
        });
    }
  },
  async mounted() {
    await this.getTodayDate();
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    childId() {
      return this.$route.params.childId;
    }
  }
};
</script>
<style lang="scss">
.vs-tooltip {
  z-index: 100000;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.margin-auto {
  margin-top: auto;
  // height: 10px;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}

.align-button {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.hidden-web {
  display: none;
}

.photo-item {
  text-align: center;
  position: relative;
}
video {
  max-width: 100%;
  height: 700px;
  &.video-slider {
    height: 100px;
  }
}

.playButton {
  object-fit: contain !important;
  margin: 0;
  text-align: center;
  position: absolute;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.story-image-container {
  position: relative;
}
.thumbnail-wrap {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  img {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    &.playButton {
      // transform: translateY(-50%);
      top: 50%;
      left: 50%;
    }
  }
}

.daily-diary-table table .inner-table tr td ul li img {
  height: 100%;
  object-fit: contain;
}
</style>
