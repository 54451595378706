<template>
  <div>
    <child-info></child-info>

    <!--Tabs -->
    <div class="mt-5 tab-wrapper">
      <!--      <vx-card class="mb-base tab-wrapper">-->
      <vs-tabs class="activity-tab">
        <vs-tab label="Profile">
          <div>
            <child-profile v-if="child" :child="child"></child-profile>
          </div>
        </vs-tab>
        <vs-tab label="Learning Stories">
          <div>
            <learning-stories v-if="child" :child="child"></learning-stories>
          </div>
        </vs-tab>
        <vs-tab label="Newsletters">
          <div>
            <news-letter v-if="child" :child="child"></news-letter>
          </div>
        </vs-tab>
        <vs-tab label="Child's Stories">
          <div>
            <child-story></child-story>
          </div>
        </vs-tab>
        <vs-tab label="Activity Log">
          <div>
            <child-daily-diary v-if="child" :child="child"></child-daily-diary>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import ChildInfo from "./ChildInfo";
import ChildProfile from "./ChildProfile";
import ChildStory from "./ChildStory";
import LearningStories from './LearningStories';
import NewsLetter from "./NewsLetter";
import ChildDailyDiary from './ChildDailyDiary';
import {mapActions} from "vuex";

export default {
  components: {
    ChildInfo,
    ChildProfile,
    ChildStory,
    LearningStories,
    NewsLetter,
    ChildDailyDiary
  },
  data() {
    return {
      teacher: '',
      selectedTab: '0',
      child: '',
    }
  },
  methods: {
    ...mapActions("child", [
      "getChildInfoById"
    ]),

    async getChild(childId) {
      await this.getChildInfoById(childId)
        .then(async (res) => {
          this.child = await res.data.data[0];
        })
        .catch(err => {
          //this.$vs.loading.close()
        })

    },
  },
  created() {
    this.getChild(this.$route.params.childId);
    if (this.$route.query.storyId && this.$route.query.storyId != "") {
      this.selectedTab = 1;
    }
    this.teacher = this.$store.state.AppActiveUser;
  }

}
</script>
<style>
.product-img {
  height: 75px;
}

.vx-card {
  border-radius: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
}

table {
  border-collapse: collapse;
}

table tr td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

table tr td:first-child {
  border-left: none;
}
</style>
